@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
html,
body {
  width: auto !important;
  overflow-x: hidden !important;
}
root#{
  width: auto !important;
  overflow-x: hidden !important;
}
body {
  width: auto !important;
  overflow-x: hidden !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  /* margin: auto; */
  align-items: center;
  padding: 0;
  box-sizing: border-box;

  /* flex-direction: inherit; */
}

body {
  background-color: rgb(248, 246, 253);
}

body,
input,
textarea,
button {
  font-family: "Inter", sans-serif;
}

.app {
  width: 200px;
  margin: 0 auto;
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  .app {
    width: 90%;
  }

  .form > input,
  textarea {
    width: 100%;
  }
  .form > textarea {
    max-width: 100%;
  }
  form > h1 {
    margin-top: 97px;
  }
}

#siteName {
  color: red;
}

/* Contact.js */
.form {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-left: 12px;
}

.form > h1 {
  margin-bottom: 30px;
}
.form > h2 {
  margin-bottom: 30px;
}

.form > input,
textarea {
  padding: 20px;
  border-radius: 3px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); */
  margin-bottom: 20px;
  border: 1px solid lightgray;
  /* border: none; */
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;

  margin-top: 51px;
}

.form > input:focus,
textarea:focus {
  border: 1px solid rgb(0, 0, 196);
}

.form > textarea {
  height: 150px;
  max-width: 400px;
  min-height: 100px;
}

.form > label {
  padding-bottom: 10px;
  color: rgb(0, 0, 32);
  font-weight: bold;
  margin-bottom: -45px;
}

@media (max-width: 600px) {
  .form > label {
    margin-bottom: -10px;
  }
}

.form > button {
  padding: 20px;
  border: none;
  background-color: rgb(2, 2, 110);
  font-weight: 500;
  font-size: 20px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 10px;
}

.form > button:hover {
  background-color: rgb(0, 0, 196);
}

nav {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  top: 0;
  padding: 1.2em 0;
  color: black;
  font-weight: bold;
  background-color: #f4f2ed;
  max-height: 4em;
  text-align: center;
}

nav a:link {
  text-decoration: none;
}

@media only screen and (max-width: 500px) {
  nav {
    left: 0;
    right: 0;
    position: fixed;
    z-index: 100000;
    top: 0;
    padding: 1.2em 0;
    color: black;
    font-weight: bold;
    background-color: #f4f2ed;
    max-height: 4em;
    text-align: center;
    margin-left: -6px;
  }
}

nav span {
  /* font-size: 18px; */
  font-size: 18px;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.2s ease;
  transition-delay: 0.25s;
  margin: 1em;
  padding-bottom: 0.3em;
}

@media (max-width: 600px) {
  nav {
    width: 416px;
  }
}

@media (max-width: 600px) {
  nav span {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  nav a {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  nav a:link {
    text-decoration: none;
  }
}

nav span:hover {
  cursor: pointer;
  border-bottom: 1px solid white;
}

nav span:hover i {
  color: gold;
}

nav span.activeClass i {
  color: gold;
}

nav span.activeClass {
  border-bottom-color: var(--lightAccent);
  /* border-bottom-color: var(--yellow); */
  color: blue;
}

#contact a {
  font-size: 10px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;
}

@media (max-width: 768px) {
  .main {
    width: 90%;
  }
}

.main h2 {
  text-align: center;
  padding-top: 150px;
}

#color {
  color: red;
  font-size: h2;
}

* {
  width: auto !important;
}

html,
body {
  width: 100% !important;
  overflow-x: hidden !important;
}

body {
  max-width: 720px;
  margin: 0 auto;
  background-color: aliceblue;
}

/* .appContainer {
  height: auto;
} */

section {
  align-items: center;
  justify-content: center;
  height: auto;
  border-bottom: 1px solid lightgrey;
}

#homeContainer {
  border-bottom: 1px solid lightgrey;
}

.line {
  border-bottom: 1px solid lightgrey;
}

.dermaroller_line {
  border-bottom: 1px solid lightgrey;
}
.llltLine {
  border-bottom: 1px solid lightgrey;
}
.recapLine {
  border-bottom: 1px solid lightgrey;
}
body {
  display: initial;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 720px;
  background-color: white;
}

#h3_solutions {
  margin-top: 50px;
  color: blue;
}
#h3_home {
  margin-top: 50px;
  color: blue;
}
#h3_dermaroller {
  margin-top: 50px;
  color: blue;
}

#h3_lllt {
  margin-top: 50px;
  color: blue;
}

p {
  /* max-width: 100%; */
  font-size: large;
  margin: 10px;
  text-align: center;
}

@media (max-width: 767px) {
  /* .mainDiv_recap {
    height: 100px;
  } */

  p {
    font-size: 17px;
    line-height: 1.3;
  }
}

section div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
}

#siteColor {
  color: red;
}

.adPlace {
  width: 1px;
  margin-left: 476px;
  height: 285px;
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
}

/* .adPlace img {
  max-width: 100%;
  height: auto;
} */
.adPlace {

  
  justify-content: center
}

.adPlace img {
  max-width: 100%;
  margin-left: -450px;
}
@media (max-width: 767px) {
  .adPlace img{
    max-width: 100%;
    height: auto;
    margin-left: -93px;
  }
}


.adPlace_keeps {
  
  width: 120px; /* Set the width to 120 pixels */
  height: 240px; /* Set the height to 240 pixels */
  display: flex; /* Define this element as a flex container */
  flex-direction: row; /* Set the direction of the flex items to be in a row */
  margin-left: 123px; /* Apply a left margin of 123 pixels */
}

@media (max-width: 767px) {
.adPlace_keeps {
  
  
  display: flex; /* Define this element as a flex container */
  flex-direction: row; /* Set the direction of the flex items to be in a row */
  margin: auto; /* Apply a left margin of 123 pixels */
}}

.nizoral{
  height: 200px;
  margin-left: 476px;
}
.nizoral img {
  max-width: 45%;
  margin-left: -187px;
  margin-top: -35px;
}
@media (max-width: 767px) {
  .nizoral {
    height: 204px;
    margin-left: 181px;
  }
  .nizoral img {
    max-width: 61%;
    margin-left: -55px;
    margin-top: -25px;
  }
}

.alpecin{
  height: 200px;
  margin-left: 476px;
}
.alpecin img {
  max-width: 38%;
  margin-left: -179px;
  margin-top: -20px;
}
@media (max-width: 767px) {
  .alpecin {
    height: 204px;
    margin-left: 181px;
  }
  .alpecin img {
    max-width: 61%;
    margin-left: -55px;
    margin-top: -25px;
  }
}
.pomade{
  height: 200px;
  margin-left: 476px;
}
.pomade img {
  max-width: 45%;
  margin-left: -179px;
  margin-top: -12px;
}
@media (max-width: 767px) {
  .pomade {
    height: 204px;
    margin-left: 181px;
  }
  .pomade img {
    max-width: 61%;
    margin-left: -55px;
    margin-top: -25px;
  }
}
.dermaroller{
  height: 200px;
  margin-left: 476px;
}
.dermaroller img {
  max-width: 94%;
  margin-left: -218px;
  margin-top: 19px;
}
@media (max-width: 767px) {
  .dermaroller {
    height: 204px;
    margin-left: 181px;
  }
  .dermaroller img {
    max-width: 101%;
    margin-left: -101px;
    margin-top: 23px;
  }
}
.irestore{
  height: 200px;
  margin-left: 476px;
}
.irestore img {
  max-width: 94%;
  margin-left: -218px;
  margin-top: 19px;
}
@media (max-width: 767px) {
  .irestore {
    height: 204px;
    margin-left: 181px;
  }
  .irestore img {
    max-width: 101%;
    margin-left: -101px;
    margin-top: 23px;
  }
}

iframe {
  width: 600px !important;
  height: 326px !important;
}

#solutionContainer {
  -webkit-transform: translateY(18px);
          transform: translateY(18px);
  border-bottom: 1px solid lightgrey;
  height: 2443px;
}

#dermarollerContainer {
  -webkit-transform: translateY(1475px);
          transform: translateY(1475px);
  border-bottom: 1px solid lightgrey;
  height: 869px;
}
#llltContainer {
  -webkit-transform: translateY(1493px);
          transform: translateY(1493px);
  border-bottom: 1px solid lightgrey;
  height: 1125px;
}

#recapContainer {
  -webkit-transform: translateY(1103px);
          transform: translateY(1103px);
  border-bottom: 1px solid lightgrey;
  /* height: 639px; */
}

.derm_rec {
  font-style: italic;
  margin-top: -23px;
}

@media only screen and (max-width: 720px) {
  body {
    max-width: 355px !important;
    overflow-x: hidden !important;
    margin: 0 auto;
  }

  #h3 {
    margin-top: 68px;
    color: blue;
  }

  #h3_recap {
    margin-top: 31px;
    color: blue;
  }

  #h3_solutions {
    margin-top: 23px;
    color: blue;
  }

  #h3_dermaroller {
    margin-top: 23px;
    color: blue;
  }

  .dermaroller_line {
    border-bottom: 1px solid lightgrey;
  }
  .recapLine {
    border-bottom: 1px solid lightgrey;
  }

  section {
    border-bottom: 1px solid lightgrey;
  }

  section div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1em;
  }

  #homeContainer {
    border-bottom: none;
  }

  #solutionContainer {
    height: 2025px;
    border-bottom: none;
  }

  #dermarollerContainer {
    height: 1352px;
    border-bottom: none;
    -webkit-transform: translateY(3007px);
            transform: translateY(3007px);
  }

  #llltContainer {
    height: 856px;
    border-bottom: none;
    -webkit-transform: translateY(2766px);
            transform: translateY(2766px);
  }

  #h3_lllt {
    margin-top: 28px;
    color: blue;
  }

  #recapContainer {
    -webkit-transform: translateY(2661px);
            transform: translateY(2661px);
    border-bottom: none;
    /* height: 639px; */
  }

  /* section {
    border-bottom: none;
  } */

  .adPlace {
    margin-left: 94px;
  }

  iframe {
    width: 200px !important;
  }
  /* .adPlace_keeps {
    flex-direction: row;
    margin-left: 0px;
  } */
  .line {
    border-bottom: 1px solid lightgrey;
    margin-top: 35px;
  }
  .solutionLine {
    border-bottom: 1px solid lightgrey;
    margin-top: -60px;
  }
  .llltLine {
    border-bottom: 1px solid lightgrey;
    margin-top: -7px;
  }
  .recapLine {
    border-bottom: 1px solid lightgrey;
    margin-top: 25px;
  }
  .derm_rec {
    font-size: 17px;
    font-style: italic;
    margin-top: -20px;
  }
}

footer {
  /* display: flex; */
  margin-top: -1741px;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  -webkit-transform: translateY(1874px);
          transform: translateY(1874px);
  height: 225px;
}
/* footer {
  display: flex;
  text-align: center;
} */

@media (max-width: 768px) {
  footer {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    -webkit-transform: translateY(3880px);
            transform: translateY(3880px);
    height: 268px;
  }
  .footerLine {
    border-bottom: 1px solid lightgrey;
    margin-top: 25px;
  }

  /* body {
    margin-top: 12px;
  } */
}
/* #contactMe {
  display: none;
} */

html,
body {
  width: auto !important;
  overflow-x: hidden !important;
}

::selection {
  color: white;
  background: rgb(23, 2, 143);
}

.appContainer {
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.testDiv {
  height: 6981px;
}

.icon {
  z-index: 2;
  position: fixed;
  right: 400px;
  top: 8%;
  /* transform: translateY(-50%); */
}

@media screen and (min-width: 768px) {
  .icon_mobile {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .icon {
    display: none;
  }
  .icon_mobile {
    z-index: 2;
    position: fixed;
    right: 312px;
    top: 8%;
  }

  .testDiv {
    height: 6709px;
  }

  /* .appContainer {
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  body {
    width: auto !important;
    overflow-x: hidden !important;
  } */
}

