html,
body {
  width: auto !important;
  overflow-x: hidden !important;
}

::selection {
  color: white;
  background: rgb(23, 2, 143);
}

.appContainer {
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.testDiv {
  height: 6981px;
}

.icon {
  z-index: 2;
  position: fixed;
  right: 400px;
  top: 8%;
  /* transform: translateY(-50%); */
}

@media screen and (min-width: 768px) {
  .icon_mobile {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .icon {
    display: none;
  }
  .icon_mobile {
    z-index: 2;
    position: fixed;
    right: 312px;
    top: 8%;
  }

  .testDiv {
    height: 6709px;
  }

  /* .appContainer {
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  body {
    width: auto !important;
    overflow-x: hidden !important;
  } */
}
