footer {
  /* display: flex; */
  margin-top: -1741px;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  transform: translateY(1874px);
  height: 225px;
}
/* footer {
  display: flex;
  text-align: center;
} */

@media (max-width: 768px) {
  footer {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    transform: translateY(3880px);
    height: 268px;
  }
  .footerLine {
    border-bottom: 1px solid lightgrey;
    margin-top: 25px;
  }

  /* body {
    margin-top: 12px;
  } */
}
/* #contactMe {
  display: none;
} */
