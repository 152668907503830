nav {
  width: 100%;
  position: sticky;
  z-index: 10;
  top: 0;
  padding: 1.2em 0;
  color: black;
  font-weight: bold;
  background-color: #f4f2ed;
  max-height: 4em;
  text-align: center;
}

nav a:link {
  text-decoration: none;
}

@media only screen and (max-width: 500px) {
  nav {
    left: 0;
    right: 0;
    position: fixed;
    z-index: 100000;
    top: 0;
    padding: 1.2em 0;
    color: black;
    font-weight: bold;
    background-color: #f4f2ed;
    max-height: 4em;
    text-align: center;
    margin-left: -6px;
  }
}

nav span {
  /* font-size: 18px; */
  font-size: 18px;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.2s ease;
  transition-delay: 0.25s;
  margin: 1em;
  padding-bottom: 0.3em;
}

@media (max-width: 600px) {
  nav {
    width: 416px;
  }
}

@media (max-width: 600px) {
  nav span {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  nav a {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  nav a:link {
    text-decoration: none;
  }
}

nav span:hover {
  cursor: pointer;
  border-bottom: 1px solid white;
}

nav span:hover i {
  color: gold;
}

nav span.activeClass i {
  color: gold;
}

nav span.activeClass {
  border-bottom-color: var(--lightAccent);
  /* border-bottom-color: var(--yellow); */
  color: blue;
}

#contact a {
  font-size: 10px;
}
