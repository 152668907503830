.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;
}

@media (max-width: 768px) {
  .main {
    width: 90%;
  }
}

.main h2 {
  text-align: center;
  padding-top: 150px;
}

#color {
  color: red;
  font-size: h2;
}
