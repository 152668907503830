* {
  width: auto !important;
}

html,
body {
  width: 100% !important;
  overflow-x: hidden !important;
}

body {
  max-width: 720px;
  margin: 0 auto;
  background-color: aliceblue;
}

/* .appContainer {
  height: auto;
} */

section {
  align-items: center;
  justify-content: center;
  height: auto;
  border-bottom: 1px solid lightgrey;
}

#homeContainer {
  border-bottom: 1px solid lightgrey;
}

.line {
  border-bottom: 1px solid lightgrey;
}

.dermaroller_line {
  border-bottom: 1px solid lightgrey;
}
.llltLine {
  border-bottom: 1px solid lightgrey;
}
.recapLine {
  border-bottom: 1px solid lightgrey;
}
body {
  display: initial;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 720px;
  background-color: white;
}

#h3_solutions {
  margin-top: 50px;
  color: blue;
}
#h3_home {
  margin-top: 50px;
  color: blue;
}
#h3_dermaroller {
  margin-top: 50px;
  color: blue;
}

#h3_lllt {
  margin-top: 50px;
  color: blue;
}

p {
  /* max-width: 100%; */
  font-size: large;
  margin: 10px;
  text-align: center;
}

@media (max-width: 767px) {
  /* .mainDiv_recap {
    height: 100px;
  } */

  p {
    font-size: 17px;
    line-height: 1.3;
  }
}

section div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
}

#siteColor {
  color: red;
}

.adPlace {
  width: 1px;
  margin-left: 476px;
  height: 285px;
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
}

/* .adPlace img {
  max-width: 100%;
  height: auto;
} */
.adPlace {

  
  justify-content: center
}

.adPlace img {
  max-width: 100%;
  margin-left: -450px;
}
@media (max-width: 767px) {
  .adPlace img{
    max-width: 100%;
    height: auto;
    margin-left: -93px;
  }
}


.adPlace_keeps {
  
  width: 120px; /* Set the width to 120 pixels */
  height: 240px; /* Set the height to 240 pixels */
  display: flex; /* Define this element as a flex container */
  flex-direction: row; /* Set the direction of the flex items to be in a row */
  margin-left: 123px; /* Apply a left margin of 123 pixels */
}

@media (max-width: 767px) {
.adPlace_keeps {
  
  
  display: flex; /* Define this element as a flex container */
  flex-direction: row; /* Set the direction of the flex items to be in a row */
  margin: auto; /* Apply a left margin of 123 pixels */
}}

.nizoral{
  height: 200px;
  margin-left: 476px;
}
.nizoral img {
  max-width: 45%;
  margin-left: -187px;
  margin-top: -35px;
}
@media (max-width: 767px) {
  .nizoral {
    height: 204px;
    margin-left: 181px;
  }
  .nizoral img {
    max-width: 61%;
    margin-left: -55px;
    margin-top: -25px;
  }
}

.alpecin{
  height: 200px;
  margin-left: 476px;
}
.alpecin img {
  max-width: 38%;
  margin-left: -179px;
  margin-top: -20px;
}
@media (max-width: 767px) {
  .alpecin {
    height: 204px;
    margin-left: 181px;
  }
  .alpecin img {
    max-width: 61%;
    margin-left: -55px;
    margin-top: -25px;
  }
}
.pomade{
  height: 200px;
  margin-left: 476px;
}
.pomade img {
  max-width: 45%;
  margin-left: -179px;
  margin-top: -12px;
}
@media (max-width: 767px) {
  .pomade {
    height: 204px;
    margin-left: 181px;
  }
  .pomade img {
    max-width: 61%;
    margin-left: -55px;
    margin-top: -25px;
  }
}
.dermaroller{
  height: 200px;
  margin-left: 476px;
}
.dermaroller img {
  max-width: 94%;
  margin-left: -218px;
  margin-top: 19px;
}
@media (max-width: 767px) {
  .dermaroller {
    height: 204px;
    margin-left: 181px;
  }
  .dermaroller img {
    max-width: 101%;
    margin-left: -101px;
    margin-top: 23px;
  }
}
.irestore{
  height: 200px;
  margin-left: 476px;
}
.irestore img {
  max-width: 94%;
  margin-left: -218px;
  margin-top: 19px;
}
@media (max-width: 767px) {
  .irestore {
    height: 204px;
    margin-left: 181px;
  }
  .irestore img {
    max-width: 101%;
    margin-left: -101px;
    margin-top: 23px;
  }
}

iframe {
  width: 600px !important;
  height: 326px !important;
}

#solutionContainer {
  transform: translateY(18px);
  border-bottom: 1px solid lightgrey;
  height: 2443px;
}

#dermarollerContainer {
  transform: translateY(1475px);
  border-bottom: 1px solid lightgrey;
  height: 869px;
}
#llltContainer {
  transform: translateY(1493px);
  border-bottom: 1px solid lightgrey;
  height: 1125px;
}

#recapContainer {
  transform: translateY(1103px);
  border-bottom: 1px solid lightgrey;
  /* height: 639px; */
}

.derm_rec {
  font-style: italic;
  margin-top: -23px;
}

@media only screen and (max-width: 720px) {
  body {
    max-width: 355px !important;
    overflow-x: hidden !important;
    margin: 0 auto;
  }

  #h3 {
    margin-top: 68px;
    color: blue;
  }

  #h3_recap {
    margin-top: 31px;
    color: blue;
  }

  #h3_solutions {
    margin-top: 23px;
    color: blue;
  }

  #h3_dermaroller {
    margin-top: 23px;
    color: blue;
  }

  .dermaroller_line {
    border-bottom: 1px solid lightgrey;
  }
  .recapLine {
    border-bottom: 1px solid lightgrey;
  }

  section {
    border-bottom: 1px solid lightgrey;
  }

  section div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1em;
  }

  #homeContainer {
    border-bottom: none;
  }

  #solutionContainer {
    height: 2025px;
    border-bottom: none;
  }

  #dermarollerContainer {
    height: 1352px;
    border-bottom: none;
    transform: translateY(3007px);
  }

  #llltContainer {
    height: 856px;
    border-bottom: none;
    transform: translateY(2766px);
  }

  #h3_lllt {
    margin-top: 28px;
    color: blue;
  }

  #recapContainer {
    transform: translateY(2661px);
    border-bottom: none;
    /* height: 639px; */
  }

  /* section {
    border-bottom: none;
  } */

  .adPlace {
    margin-left: 94px;
  }

  iframe {
    width: 200px !important;
  }
  /* .adPlace_keeps {
    flex-direction: row;
    margin-left: 0px;
  } */
  .line {
    border-bottom: 1px solid lightgrey;
    margin-top: 35px;
  }
  .solutionLine {
    border-bottom: 1px solid lightgrey;
    margin-top: -60px;
  }
  .llltLine {
    border-bottom: 1px solid lightgrey;
    margin-top: -7px;
  }
  .recapLine {
    border-bottom: 1px solid lightgrey;
    margin-top: 25px;
  }
  .derm_rec {
    font-size: 17px;
    font-style: italic;
    margin-top: -20px;
  }
}
